/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import HeaderSection from '../components/HeaderSection';
import MainContentSection from '../components/MainContentSection';
import FloatingBar from '../components/FloatingBar';
import FloatingLines from '../components/FloatingLines';

export default function Home() {
  const ref = useRef(null);

  function useIsInViewport(r) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    let observer = null;
    if (typeof window !== 'undefined') {
      observer = new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      );
    }

    useEffect(() => {
      observer.observe(r.current);

      return () => {
        observer.disconnect();
      };
    }, [r, observer]);

    return isIntersecting;
  }

  const isInViewport = useIsInViewport(ref);

  return (
    <section className="bg-custom-white text-custom-black dark:bg-custom-black dark:text-custom-white">
      <HeaderSection />
      <MainContentSection />
      <div ref={ref} />
      {!isInViewport && <FloatingBar />}
      <FloatingLines />
    </section>
  );
}

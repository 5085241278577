import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

import DarkModeIcon from '../images/svg/dark-mode-icon.svg';
import LightModeIcon from '../images/svg/light-mode-icon.svg';

export default function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) =>
        theme === 'light' ? (
          <button type="button" onClick={() => toggleTheme('dark')}>
            <DarkModeIcon />
          </button>
        ) : (
          <button type="button" onClick={() => toggleTheme('light')}>
            <LightModeIcon />
          </button>
        )
      }
    </ThemeToggler>
  );
}

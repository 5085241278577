import React from 'react';
import GithubIcon from '../images/svg/github-icon.svg';
import FacebookIcon from '../images/svg/facebook-icon.svg';
import InstagramIcon from '../images/svg/instagram-icon.svg';
import LinkedinIcon from '../images/svg/linkedin-icon.svg';

export default function SocialLinks({ vertical }) {
  return (
    <div className={`flex gap-2${vertical ? ' flex-col' : ' flex-row'}`}>
      <a href="https://facebook.com">
        <GithubIcon />
      </a>
      <a href="https://facebook.com">
        <FacebookIcon />
      </a>
      <a href="https://facebook.com">
        <InstagramIcon />
      </a>
      <a href="https://facebook.com">
        <LinkedinIcon />
      </a>
    </div>
  );
}

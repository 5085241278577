import React from 'react';
import FooterSection from './FooterSection';

export default function MainContentSection() {
  return (
    <div className="m-auto text-center p-3 md:max-w-[610px]">
      <div className="mt-11">
        <p className="accentText text-5xl md:text-7xl ">Hello, World!</p>
        <p className="mt-9 md:text-2xl">
          <strong className="font-black">I’m Charmaine Eunice Rabano.</strong> A
          Computer Science student. An aspiring software developer. Someone who
          enjoys building things from nothing and bringing vision into life.{' '}
        </p>
      </div>
      <div className="mt-14 lg:my-52">
        <h2 className="hidden font-bold mb-12 lg:block">Get In Touch</h2>
        <a href="mailto:charmaine.rabano@gmail.com" className="btn">
          you can reach me here
        </a>
      </div>
      <FooterSection />
    </div>
  );
}

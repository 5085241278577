import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function HeaderSection() {
  return (
    <div className="relative p-3">
      <div className="pinkBorder bg-custom-dark-pink">
        <StaticImage
          src="../images/banner1.jpeg"
          className="h-[200px] md:h-[300px] opacity-70 hover:opacity-100"
          placeholder="tracedSVG"
          quality="30"
        />
      </div>
      <div className="absolute -bottom-6 lg:-bottom-24 right-6 xl:right-52 md:right-14 pinkBorder bg-custom-dark-pink">
        <StaticImage
          src="../images/profile.jpg"
          className="h-[95px] w-[95px] md:h-[140px] md:w-[140px] opacity-70 hover:opacity-100"
          placeholder="tracedSVG"
          quality="30"
        />
      </div>
    </div>
  );
}
